var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Ae2CreditCardsService, Ae2CreditCardPayloadModel } from '@angularecommerce/core/services/credit-cards';
import { Ae2AngularPayService, Ae2AngularPayCreateTokenModel } from '@angularecommerce/core/services/angular-pay';
import { Ae2AngularPay2Service, Ae2AngularPay2TokenizerPayloadModel } from '@angularecommerce/core/services/angular-pay2';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2SettingsService } from '@angularecommerce/core/services/settings';
import { Ae2PointsOfSaleService } from '@angularecommerce/core/services/points-of-sale';
import { ae2DesestructureExpireDate } from '@angularecommerce/core/functions/desestructure';
import { ae2CreditCardNumberValidator } from '@angularecommerce/core/functions/credit-card-number-validator';
import { ae2CreditCardCvvValidator } from '@angularecommerce/core/functions/credit-card-cvv-validator';
import { ae2CreditCardExpirationDateValidator } from '@angularecommerce/core/functions/credit-card-expiration-date-validator';
import { TranslationService, Language, DefaultLocale, Timezone, Currency } from 'angular-l10n';
import { Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { captureException } from '@sentry/browser';
var UserCardsFormComponent = /** @class */ (function () {
    function UserCardsFormComponent(data, dialogRef, ae2CreditCardsService, ae2AngularPayService, ae2AngularPay2Service, ae2FeedbackService, translationService, ae2SettingsService, ae2HttpResponseMessagesService, ae2PointsOfSaleService) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.ae2CreditCardsService = ae2CreditCardsService;
        this.ae2AngularPayService = ae2AngularPayService;
        this.ae2AngularPay2Service = ae2AngularPay2Service;
        this.ae2FeedbackService = ae2FeedbackService;
        this.translationService = translationService;
        this.ae2SettingsService = ae2SettingsService;
        this.ae2HttpResponseMessagesService = ae2HttpResponseMessagesService;
        this.ae2PointsOfSaleService = ae2PointsOfSaleService;
        this.usePayV1 = false;
        this.showSelectPosMessage = true;
        this.isRetry = false;
        this.destroy$ = new Subject();
    }
    UserCardsFormComponent.prototype.ngOnInit = function () {
        this.usePayV1 = this.ae2SettingsService.settings.site.usePayV1;
        this.initForm();
        this.initPos();
        this.getPaymentCustumers();
    };
    UserCardsFormComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
    };
    UserCardsFormComponent.prototype.getPaymentCustumers = function () {
        var _this = this;
        this.ae2PointsOfSaleService.payCustomers(this.ae2SettingsService.settings.payments.useFixedPayCustomerForCards).subscribe(function (res) {
            _this.publicKey = res.payKeyPublic;
        }, function (error) {
            captureException(error);
        });
    };
    UserCardsFormComponent.prototype.initPos = function () {
        this.showSelectPosMessage = false;
        this.form.get('payCustomer').setValue(this.ae2SettingsService.settings.payments.useFixedPayCustomerForCards);
    };
    UserCardsFormComponent.prototype.initForm = function () {
        this.form = new FormGroup({
            payCustomer: new FormControl('', { updateOn: 'change' }),
            number: new FormControl('', [Validators.required, ae2CreditCardNumberValidator]),
            cardholderName: new FormControl('', [Validators.required]),
            expireDate: new FormControl('', [Validators.required, ae2CreditCardExpirationDateValidator]),
            isMain: new FormControl(false, { updateOn: 'change' }),
            cvv: new FormControl('', {
                updateOn: 'change',
                validators: [Validators.required, ae2CreditCardCvvValidator]
            }),
        }, {
            updateOn: 'blur'
        });
        if (!this.usePayV1) {
            this.form.get('payCustomer').setValidators(Validators.required);
        }
    };
    UserCardsFormComponent.prototype.close = function () {
        this.dialogRef.close({ success: false });
    };
    UserCardsFormComponent.prototype.save = function () {
        var _this = this;
        if (this.form.invalid) {
            return;
        }
        this.createToken().pipe(mergeMap(function (token) {
            var data = new Ae2CreditCardPayloadModel(_this.data.user.id, token.token, _this.form.value.cardholderName, _this.form.value.isMain, _this.form.value.expireDate, token.maskedCardNumber);
            data.payCustomer = _this.usePayV1 ? null : _this.ae2SettingsService.settings.payments.useFixedPayCustomerForCards;
            return _this.ae2CreditCardsService.post(data);
        }))
            .subscribe(function (res) {
            var message = 'Cartão de crédito cadastrado com sucesso';
            _this.ae2FeedbackService.success({ message: message, format: Ae2FeedbackFormat.Notification });
            _this.dialogRef.close({ success: true, card: res });
        }, function (err) {
            _this.dialogRef.close({ success: false });
            _this.ae2HttpResponseMessagesService.createFeedback(err);
        });
    };
    UserCardsFormComponent.prototype.createToken = function () {
        var expireDateFragments = ae2DesestructureExpireDate(this.form.get('expireDate').value);
        if (this.usePayV1) {
            var createToken = new Ae2AngularPayCreateTokenModel(this.form.value.number, this.form.value.cardholderName, this.form.value.cvv, expireDateFragments.month, expireDateFragments.year, true);
            return this.ae2AngularPayService.createToken(createToken);
        }
        else {
            if (!this.publicKey) {
                this.getPaymentCustumers();
                return;
            }
            var createToken = new Ae2AngularPay2TokenizerPayloadModel();
            createToken.publicKey = this.publicKey;
            createToken.userId = this.data.user.id;
            createToken.userName = this.data.user.firstName + " " + this.data.user.lastName;
            createToken.cardNumber = this.form.get('number').value;
            createToken.holderName = this.form.get('cardholderName').value;
            createToken.securityCode = this.form.get('cvv').value;
            createToken.expirationDate = expireDateFragments.month + "/" + expireDateFragments.year;
            return this.ae2AngularPay2Service.tokenizer(createToken);
        }
    };
    __decorate([
        Language(),
        __metadata("design:type", String)
    ], UserCardsFormComponent.prototype, "lang", void 0);
    __decorate([
        DefaultLocale(),
        __metadata("design:type", String)
    ], UserCardsFormComponent.prototype, "defaultLocale", void 0);
    __decorate([
        Timezone(),
        __metadata("design:type", String)
    ], UserCardsFormComponent.prototype, "tz", void 0);
    __decorate([
        Currency(),
        __metadata("design:type", String)
    ], UserCardsFormComponent.prototype, "currency", void 0);
    return UserCardsFormComponent;
}());
export { UserCardsFormComponent };
