import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { Ae2UserModel } from '@angularecommerce/core/services/users';
import { Ae2CreditCardsService, Ae2CreditCardPayloadModel } from '@angularecommerce/core/services/credit-cards';
import { Ae2AngularPayService, Ae2AngularPayCreateTokenModel, Ae2AngularPayTokenModel } from '@angularecommerce/core/services/angular-pay';
import { Ae2AngularPay2Service, Ae2AngularPay2TokenizerModel,
  Ae2AngularPay2TokenizerPayloadModel } from '@angularecommerce/core/services/angular-pay2';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { Ae2HttpResponseMessagesService } from '@angularecommerce/core/services/http-response-messages';
import { Ae2SettingsService } from '@angularecommerce/core/services/settings';
import { Ae2PointsOfSaleModel, Ae2PointsOfSaleService } from '@angularecommerce/core/services/points-of-sale';
import { ae2DesestructureExpireDate } from '@angularecommerce/core/functions/desestructure';
import { ae2CreditCardNumberValidator } from '@angularecommerce/core/functions/credit-card-number-validator';
import { ae2CreditCardCvvValidator } from '@angularecommerce/core/functions/credit-card-cvv-validator';
import { ae2CreditCardExpirationDateValidator } from '@angularecommerce/core/functions/credit-card-expiration-date-validator';
import { TranslationService, Language, DefaultLocale, Timezone, Currency } from 'angular-l10n';
import { Observable, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { captureException } from '@sentry/browser';

export interface UserCardsFormData {
  user: Ae2UserModel;
  pos?: Ae2PointsOfSaleModel;
  isRetry?: boolean;
}

@Component({
  selector: 'app-user-cards-form',
  templateUrl: './user-cards-form.component.html',
  styleUrls: ['./user-cards-form.component.sass'],
  providers: [Ae2AngularPayService]
})
export class UserCardsFormComponent implements OnInit, OnDestroy {
  @Language() lang: string;
  @DefaultLocale() defaultLocale: string;
  @Timezone() tz: string;
  @Currency() currency: string;

  form: FormGroup

  usePayV1: boolean = false
  showSelectPosMessage: boolean = true
  isRetry: boolean = false

  private destroy$: Subject<any> = new Subject();
  public pos: Ae2PointsOfSaleModel;

  public publicKey: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: UserCardsFormData,
    private dialogRef: MatDialogRef<UserCardsFormComponent>,
    private ae2CreditCardsService: Ae2CreditCardsService,
    private ae2AngularPayService: Ae2AngularPayService,
    private ae2AngularPay2Service: Ae2AngularPay2Service,
    private ae2FeedbackService: Ae2FeedbackService,
    private translationService: TranslationService,
    private ae2SettingsService: Ae2SettingsService,
    private ae2HttpResponseMessagesService: Ae2HttpResponseMessagesService,
    private ae2PointsOfSaleService: Ae2PointsOfSaleService
  ) {
  }

  ngOnInit(): void {
    this.usePayV1 = this.ae2SettingsService.settings.site.usePayV1
    this.initForm();
    this.initPos();
    this.getPaymentCustumers();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getPaymentCustumers(): void {
    this.ae2PointsOfSaleService.payCustomers(this.ae2SettingsService.settings.payments.useFixedPayCustomerForCards).subscribe((res) => {
      this.publicKey = res.payKeyPublic;
    }, (error) => {
      captureException(error);
    });
  }

  initPos(): void {
    this.showSelectPosMessage = false
    this.form.get('payCustomer').setValue(this.ae2SettingsService.settings.payments.useFixedPayCustomerForCards)
  }

  initForm(): void {
    this.form = new FormGroup({
      payCustomer: new FormControl('', { updateOn: 'change' }),
      number: new FormControl('', [Validators.required, ae2CreditCardNumberValidator]),
      cardholderName: new FormControl('', [Validators.required]),
      expireDate: new FormControl('', [Validators.required, ae2CreditCardExpirationDateValidator]),
      isMain: new FormControl(false, { updateOn: 'change' }),
      cvv: new FormControl('', {
        updateOn: 'change',
        validators: [Validators.required, ae2CreditCardCvvValidator]
      }),
    }, {
      updateOn: 'blur'
    })

    if (!this.usePayV1) {
      this.form.get('payCustomer').setValidators(Validators.required)
    }
  }

  close(): void {
    this.dialogRef.close({ success: false });
  }

  save(): Observable<boolean> {
    if (this.form.invalid) { return; }

    this.createToken().pipe(
      mergeMap((token: Ae2AngularPayTokenModel | Ae2AngularPay2TokenizerModel) => {
        const data = new Ae2CreditCardPayloadModel(
          this.data.user.id,
          token.token,
          this.form.value.cardholderName,
          this.form.value.isMain,
          this.form.value.expireDate,
          token.maskedCardNumber,
        );

        data.payCustomer = this.usePayV1 ? null : this.ae2SettingsService.settings.payments.useFixedPayCustomerForCards

        return this.ae2CreditCardsService.post(data)
      })
    )
      .subscribe(
        (res) => {
          const message = 'Cartão de crédito cadastrado com sucesso'
          this.ae2FeedbackService.success({ message, format: Ae2FeedbackFormat.Notification });
          this.dialogRef.close({ success: true, card: res })
        },
        err => {
          this.dialogRef.close({ success: false })
          this.ae2HttpResponseMessagesService.createFeedback(err)
        }
      );
  }

  createToken(): Observable<Ae2AngularPayTokenModel | Ae2AngularPay2TokenizerModel> {
    const expireDateFragments = ae2DesestructureExpireDate(this.form.get('expireDate').value);

    if (this.usePayV1) {
      const createToken = new Ae2AngularPayCreateTokenModel(
        this.form.value.number,
        this.form.value.cardholderName,
        this.form.value.cvv,
        expireDateFragments.month,
        expireDateFragments.year,
        true)

      return this.ae2AngularPayService.createToken(createToken)

    } else {
      if (!this.publicKey) {
        this.getPaymentCustumers();
        return;
      }
      const createToken = new Ae2AngularPay2TokenizerPayloadModel()
      createToken.publicKey = this.publicKey;
      createToken.userId = this.data.user.id;
      createToken.userName = `${this.data.user.firstName} ${this.data.user.lastName}`;
      createToken.cardNumber = this.form.get('number').value;
      createToken.holderName = this.form.get('cardholderName').value;
      createToken.securityCode = this.form.get('cvv').value;

      createToken.expirationDate = `${expireDateFragments.month}/${expireDateFragments.year}`
      return this.ae2AngularPay2Service.tokenizer(createToken)
    }
  }

}
